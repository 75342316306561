export const environment = {
  production: true,
  isLive: false,
  AppName: "API Connector",
  multi_API_ENDPOINT: {
    facebook: {
      name: "Facebook",
      API_ENDPOINT: "https://fbdev.cifapps.com/remote/public/",
      default: true,
      Bearer:   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjM4NGI1NzA2ZjcyNWUzMzEwMDI4ZWY5Iiwicm9sZSI6ImFkbWluIiwiZXhwIjoxNzE1ODQ1MTY4LCJpc3MiOiJodHRwczpcL1wvYXBwcy5jZWRjb21tZXJjZS5jb20iLCJ0b2tlbl9pZCI6IjY0NjQ4NGIwMGI5YzQ5MTdhNjA0YWM0MiJ9.dZazVQ-9i-BYn41VT9JtGUpuMwl-wk0jVbEwV4keLx9WOhI6N0uCZMaSffkuIRUZmza-mHMCdC1-NbJEyU37RLJmAouDaDUmZN38IutIKgCcA_COgLh12HA32DFds2QPI5weqV7nMz3Emkte6waZcRwLdn34piRt-E9jcd3MGjWt-iPhSffDyZcdWyhsbod7ddNrzCKZm0UCyJNRMMlO5P1SmxxS9kIzKigrnxJV9xqY4rALEaj0e2ucf4meFs5f80SMmv4QDR0H3A2CJ3fwMljUmix1aaCoMMXywaEwcWyGB6N5gbtol-8olDMiUcOsb99sZQ18Ij-a7zhkyPt5tQ",
    },
  },
};
/* Bearer is App token for server */
